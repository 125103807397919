@mixin resumeSectionText {
  border-bottom: 1px solid $color--black;
  font-weight: $font--weight-normal;
  font-size: $font--size-body;
  letter-spacing: 1px;
  margin-bottom: $padding--normal;
  padding-bottom: $padding--normal;
  text-transform: uppercase;
}

@mixin animatedUnderline($color) {
  color: $color;
  position: relative;
  text-decoration: none;

  &:after {
    background-color: $color;
    content: "";
    height: 1px;
    transition: all 0.1s;
    width: 0;

    position: absolute;
    right: 0;
    bottom: -$padding--small;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}

@mixin buttonText {
  cursor: pointer;
  font-size: $font--size-body;
  font-weight: $font--weight-normal;
  letter-spacing: 1px;
  max-width: 300px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.1s;
  width: 100%;
}

@mixin button {
  @include buttonText;
  background-color: transparent;
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 3px;
  padding: $padding--normal;
}

@mixin superText {
  font-size: $font--size-small;
  font-weight: $font--weight-normal;
  margin-top: 0;
  margin-bottom: $padding--small;
}

@mixin fieldField {
  display: block;
  max-width: 100%;
  position: relative;
}

@mixin fieldLabel {
  color: $tint--dark;
  font-family: $font--family-body;
  font-size: $font--size-contextual;
  font-weight: $font--weight-normal;
  position: absolute;
  top: 10px;
  left: 10px;
  text-transform: uppercase;
}

@mixin fieldInput {
  background-color: $color--white;
  border: 1px solid $tint--medium;
  box-shadow: inset $effect--shadow-down;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-family: $font--family-forms;
  font-size: $font--size-body;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 32px;
  padding-bottom: 8px;
  transition: box-shadow $effect--transition-default;
  width: 100%;

  &:focus {
    outline: none;
    border: 1px solid $tint--dark;
  }
}
.Button {

  &--root {
    @include button;

    &:focus {
      outline: none;
    }
  }

}
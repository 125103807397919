.Resume {

  &--root {
    background-color: $color--white;
  }

  &--sections {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: auto;
    max-width: $width--column;
  }

  &--section {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: flex-start;
    padding: $padding--big;
    width: 100%;

    @include desktop {
      min-height: 50vh;
      width: 50%;
    }
  }

  &--title {
    font-size: $font--size-h2;
  }

  &--summary {
    font-size: $font--size-lead-mobile;
    font-weight: $font--weight-normal;
    line-height: 1.4;
    margin-top: 0;

    @include desktop {
      font-size: $font--size-lead;
      margin-bottom: 0;
    }
  }

  &--download {
    @include button;
    border-color: $color--blue;
    color: $color--blue;
    margin-top: $padding--normal;

    &:hover {
      border-color: transparentize($color--red, 0.8);
      color: $color--red;
    }
  }

}
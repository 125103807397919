.EmailForm {

  &--inputs {
    background-color: $color--white;
    border: 1px solid $tint--medium;
    box-shadow: 0 4px 10px $tint--light;
    padding: $padding--normal;
    position: relative;
    transition: all 0.5s;

    &:after {
      background-color: transparent;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      transition: all 0.5s;
    }
  }

  &--submitting .EmailForm--inputs {
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: none;

    &:after {
      background-color: transparentize($color--light, 0.2);
      z-index: 0;
    }
  }

  &--group {
    margin-bottom: $padding--normal;

    @include desktop {
      display: flex;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &--field {
    box-sizing: border-box;
    width: 100%;
  }

  &--field:nth-child(2n - 1) {
    @include desktop {
      margin-right: $padding--normal;
    }

    @include tablet-down {
      margin-bottom: $padding--normal;
    }
  }

  &--field:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  &--button {
    @include button;
    border-color: $color--blue;
    color: $color--blue;
    margin-top: $padding--normal;

    &:hover {
      border-color: transparentize($color--red, 0.8);
      color: $color--red;
    }

    &:disabled {
      border-color: $tint--medium;
      color: $tint--medium;
    }
  }

  &--button.EmailForm--button-sent {
    border-color: $color--green;
    color: $color--green;
  }
}
.Education {

  &--section-title {
    @include resumeSectionText;
  }

  &--ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &--school {
    margin-bottom: $padding--normal;
  }

}
html {
  background-color: $color--black;
}

html,
body {
  font-family: $font--family-body;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}
// Color Variables

$color--white: #ffffff;
$color--black: #000000;
$color--light: #f9f9f9;

$color--orange: #fadb9d;
$color--blue: #6198f2;
$color--green: #6c9480;
$color--red: #be7c8a;

$color-mod--light: #111;
$color-mod--medium: #333;
$color-mod--strong: #555;

$opacity--light: 0.05;
$opacity--medium: 0.1;
$opacity--dark: 0.4;
$opacity--black: 0.7;

$tint--light: rgba(0, 0, 0, $opacity--light);
$tint--medium: rgba(0, 0, 0, $opacity--medium);
$tint--dark: rgba(0, 0, 0, $opacity--dark);
$tint--black: rgba(0, 0, 0, $opacity--black);

$dim--light: rgba(249, 249, 249, 0.95);

// Effect Variables

$effect--shadow-up: 0 -3px 0 $tint--light;
$effect--shadow-down: 0 3px 0 $tint--light;
$effect--shadow-up-deep: 0 -5px 0 $tint--light;
$effect--shadow-down-deep: 0 5px 0 $tint--light;
$effect--transition-default: 0.25s;

// Font Variables

$font--family-body: 'Libre Franklin', sans-serif;
$font--family-heading: $font--family-body, serif;
$font--family-forms: 'Roboto Mono', monospace;

$font--weight-normal: 400;
$font--weight-light: 100;
$font--weight-heavy: 700;

$font--size-h1: 50px;
$font--size-h1-mobile: 36px;
$font--size-h2: 28px;
$font--size-h3: 20px;

$font--size-hero: 60px;
$font--size-lead: 36px;
$font--size-lead-mobile: 32px;
$font--size-body: 16px;
$font--size-small: 14px;
$font--size-contextual: 12px;

$font--leading-content: 1.6;
$font--leading-heading: 1.1;
$font--leading-none: 1;

// Layout Variables

$width--column: 1200px;
$width--breakpoint: 900px;
$width--breakpoint-mobile: 600px;

$padding--normal: 20px;
$padding--small: 10px;
$padding--big: 60px;
.Experience {

  &--section-title {
    @include resumeSectionText;
  }

  &--jobs {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &--job {
    margin-bottom: $padding--normal;
  }

  &--call-to-action {
    @include buttonText;
    color: $color--blue;
    margin-top: $padding--normal;

    &:hover {
      color: $color--red;
    }
  }

}
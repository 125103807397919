.Header {

  &--root {
    color: white;
    position: relative;
    transition: all 1s;
  }

  &--background {
    background-size: 800px;
    background-position-x: center;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: opacity 1s;

    @include tablet-down {
      background-size: cover;
    }

    &:before {
      background: $tint--black;
      background: linear-gradient(20deg, rgba(0,0,0,0.9) 5%, rgba(0,0,0,0.5) 100%);
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }
  }

  &--fade-in.Header--background {
    opacity: 1;
  }

  &--container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 90vh;
    margin: auto;
    max-width: $width--column;
    padding: $padding--big;

    @include tablet-up {
      min-height: 100vh;
    }
  }

  &--titles {
    margin-bottom: auto;
    margin-top: auto;
    opacity: 0;
    padding-bottom: $padding--big;
    position: relative;
    transition: all 0.3s;
    transform: translateY($padding--big);
  }

  &--fade-in.Header--titles {
    opacity: 1;
    transform: translateY(0);
  }

  &--title {
    font-size: $font--size-h1-mobile;
    font-weight: $font--weight-heavy;
    margin-bottom: $padding--normal;

    @include desktop {
      font-size: $font--size-hero;
    }
  }

  &--subtitle {
    font-size: $font--size-h2;
    font-weight: $font--weight-normal;
  }

  &--company {
    font-style: italic;
    font-weight: $font--weight-light;
  }

  &--company-link {
    @include animatedUnderline($color--orange);
  }

  &--actions {
    opacity: 0;
    position: relative;
    transition: opacity 0.3s;
  }

  &--fade-in.Header--actions {
    opacity: 1;
  }

  &--action-resume {
    border: 0;
    border-radius: 0;
    color: $color--orange;

    &:hover {
      border-color: transparentize($color--orange, 0.8);
      color: $color--white;
    }
  }

  &--action-contact {
    border-color: $color--orange;
    color: $color--orange;
    margin-right: $padding--normal;

    &:hover {
      border-color: transparentize($color--orange, 0.8);
      color: $color--white;
    }
  }
}
@mixin desktop() {
  @media screen and (min-width: $width--breakpoint) {
    @content;
  }
}

@mixin tablet-up() {
  @media screen and (min-width: $width--breakpoint-mobile) {
    @content;
  }
}

@mixin tablet-only() {
  @media screen and (max-width: $width--breakpoint) and (min-width: $width--breakpoint-mobile) {
    @content;
  }
}

@mixin tablet-down() {
  @media screen and (max-width: $width--breakpoint) {
    @content;
  }
}

@mixin mobile() {
  @media screen and (max-width: $width--breakpoint-mobile) {
    @content;
  }
}
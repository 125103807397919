.Job {

  &--root {
    display: block;
  }

  &--dates {
    font-size: $font--size-small;
    margin-top: 0;
    margin-bottom: $padding--small;
  }

  &--title {
    font-size: $font--size-h3;
    font-weight: $font--weight-normal;
  }

  &--name {
    font-weight: $font--weight-heavy;
  }

  &--company {
    font-style: italic;
  }

}
.TextField {

  &--root {
    @include fieldField;
  }

  &--label {
    @include fieldLabel;
  }

  &--input {
    @include fieldInput;
  }

  &--invalid .TextField--input {
    border-color: $color--red;
  }

}
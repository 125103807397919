.Skills {

  &--section-title {
    @include resumeSectionText;
  }

  &--title {
    @include superText;
  }

  &--lists {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &--list {
    box-sizing: border-box;
    flex: 1 0 auto;
    padding-right: $padding--normal;
    width: 100%;

    @include tablet-up {
      width: 50%;
    }
  }

  &--ul {
    line-height: $font--leading-content;
    list-style: none;
    margin: 0;
    margin-bottom: $padding--normal;
    padding: 0;
  }

  &--skill {
    font-size: $font--size-h3;
  }

  &--call-to-action {
    @include buttonText;
    color: $color--blue;
    margin-top: $padding--normal;

    &:hover {
      color: $color--red;
    }
  }

}
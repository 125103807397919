.TextAreaField {

  &--root {
    @include fieldField;
  }

  &--label {
    @include fieldLabel;
  }

  &--input {
    @include fieldInput;
    resize: vertical;
  }
}
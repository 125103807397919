.Footer {

  &--root {
    background-color: $color--light;
  }

  &--container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: $width--column;
    min-height: 100vh;
    padding: $padding--big;
  }

  &--form {
    margin-bottom: $padding--big;

    @include desktop {
      max-width: 50%;
    }
  }

  &--ul {
    font-size: $font--size-h3;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &--item {
    display: block;
    font-weight: $font--weight-normal;
    margin-bottom: $padding--normal;
  }

  &--email {
    @include button;
    border-color: $color--blue;
    color: $color--blue;
    margin-bottom: $padding--big;

    &:hover {
      border-color: transparentize($color--red, 0.8);
      color: $color--red;
    }
  }

  &--link {
    @include animatedUnderline($color--black);
    color: $color--black;
    display: inline-block;
  }

  &--title {
    font-size: $font--size-h2;
    margin-bottom: $padding--big;
  }

  &--copyright {
    box-sizing: border-box;
    flex: 0 0 auto;
    font-size: $font--size-contextual;
    margin-top: auto;
    padding-top: $padding--normal;
    width: 100%;
  }
}
.School {

  &--title {
    font-size: $font--size-h3;
    font-weight: $font--weight-normal;
  }

  &--degree {
    font-weight: $font--weight-heavy;
  }

  &--school {
    font-style: italic;
  }

  &--dates {
    @include superText;
  }

}